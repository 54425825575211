import template from './ko-input.html';
import ControlVM from './ko-control.js'
import {resolveValueRef} from '../lib/Observables.js';

class ComponentVM extends ControlVM{
	constructor(params) {
		super(params);
		this.type =  ko.observable(this.data().properties.inputType || "text");
		this.pattern =  ko.observable(this.data().properties.pattern);
		this.showLabel = this.data().properties.showLabel ?? false;
		this.gridColumn = ko.observable(this.data().properties.gridColumn);
		this.gridRow = ko.observable(this.data().properties.gridRow);
		this.value = ko.observable();
		this.data_model_value = resolveValueRef({
			valueRef: this.scope(),
			observable: this.formData.form,
			resultType: 'observable'
		});
		this.data_model_value.subscribe((newValue)=>{
			this.value(newValue);
		});
		this.value(this.data_model_value());
		this.value.subscribe((newValue)=>{
			this.data_model_value(newValue)
		});
		// console.log(this);
	}

};

window.FormsClient.registerUIComponent({type:'input',ui_component_name:'ko-input'});

export default {
	name: 'ko-input',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
