import template from './ko-list.html';
import {resolveValueRef} from '../lib/Observables.js';

class ComponentVM {
	self;
	constructor(params) {
		self = this;
		this.formData = params.formData;
		// console.log('LIST formData', this.formData.form());
		this.data = ko_helper.safe_observable(params.data || {});
		this.gridDisplay = ko.observable(this.data().properties.gridDisplay);
		this.gridColumns = ko.observable(this.data().properties.gridColumns);
		this.gridRows = ko.observable(this.data().properties.gridRows);
		this.gridColumn = ko.observable(this.data().properties.gridColumn);
		this.gridRow = ko.observable(this.data().properties.gridRow);
		this.rows = ko_helper.safe_observableArray(this.data().properties.rows || []);
		this.scope = ko.observable(this.data().properties.scope);
		this.data_model_value = resolveValueRef({
			valueRef: this.scope(),
			observable: this.formData.form,
			resultType: 'observable'
		});
		this.rows(this.data_model_value());
	}

	addRow(){
		// console.log('#############',JSON.stringify(this.data_model_value()[0]));
		this.data_model_value.push(JSON.parse(JSON.stringify(this.data_model_value()[0])));
		// console.log('add row:',this.formData.form());
		this.rows(this.data_model_value());
	}

	removeRow(index, vm){ //TODO fix remove and update rows properly
		// console.log(arguments);
		// console.log(index, vm.data_model_value(), vm.rows());
		vm.data_model_value.splice(index,1);
		vm.rows(vm.data_model_value());
		// console.log(index, vm.data_model_value(), vm.rows());
	}

};

window.FormsClient.registerUIComponent({type:'List',ui_component_name:'ko-list'});

export default {
	name: 'ko-list',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
