import template from './ko-unknown.html';

class ComponentVM {
	constructor(params) {
		// console.log(`params: ${params.toString()}`);
		this.data = ko_helper.safe_observable(params.data || {});
		this.error_text = ko.observable(`Unknown form ui model type "${this.data().type}"`);
	}

};

export default {
	name: 'ko-unknown',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
