class Observable extends Function {
	constructor(initialValue){
		super('return arguments.callee._call.apply(arguments.callee, arguments)');
		this.subscriptions = [];
		this.child_subscriptions = {};
		this._value = this.setValue(initialValue);
		this._type;
	}

	_call(newValue){
		if (newValue){
			// console.log('setting value');
			return this.setValue(newValue);
		}

		return this.getValue();
	}

	getValue(){
		// console.log('getValue: ',this._value);
		if (typeof this._value === "object"){
			let value = {};
			if (Array.isArray(this._value))
				value = [];

			for (let [attrName, attrValue] of Object.entries(this._value)){
				value[attrName] = attrValue();
			}
			return value;
		}
		return this._value;
	}

	setValue(newValue){
		// console.log('newValue', newValue);
		if (typeof this._value === "object"){ // TODO allow setting object type by setting nested observables?
			// console.log('current set value is object type');
			return this.getValue();
		}

		if (typeof newValue === "object"){

			// console.log('building object ', newValue);
			this._value = {};
			if (Array.isArray(newValue))
				this._value = [];

			for (let [attrName, attrValue] of Object.entries(newValue)){
				// console.log('adding ', attrName, attrValue);
				this._value[attrName] = new Observable(attrValue);
				this[attrName] = this._value[attrName];
			}
			return this._value;
		}

		if (this._value === newValue)
			return this.getValue();

		this._value = newValue;
		this.runSubscriptions();

		return this.getValue();
	}

	push(newValue){
		// console.log('push',newValue);
		if (!Array.isArray(this._value))
			return;

		let value = new Observable(newValue);
		this._value.push(value);
		// console.log(value);
		this[(this._value.length-1).toString()] = value;
		this.runSubscriptions();
	}

	splice(start, steps){
		if (!Array.isArray(this._value))
			return;

		this._value.splice(start, steps);
		this.runSubscriptions();
	}

	runSubscriptions(){
		for (let sub of this.subscriptions){
			sub(this.getValue());
		}
	}

	subscribe(callback) {
		this.subscriptions.push(callback);
		if (typeof this._value === 'object'){
			// console.log('subscribing on an object type', this._value);
			for (let [attrName, attrValue] of Object.entries(this._value)){
				// console.log('sunbscribe checking', attrName, attrValue, typeof attrValue);
				if (typeof attrValue === 'function'){
					// console.log('subscribe to subvalue');
					this.child_subscriptions[callback] = ()=>{this.runSubscriptions()}
					attrValue.subscribe(this.child_subscriptions[callback])
				};
			}
		}
	}

	unsubscribe(callback) {
		this.subscriptions.splice(this.subscriptions.indexOf(callback));
		if (typeof this._value === 'object'){
			// console.log('unsubscribing on an object type', this._value);
			for (let [attrName, attrValue] of Object.entries(this._value)){
				// console.log('unsubscribe checking', attrName, attrValue, typeof attrValue);
				if (typeof attrValue === 'function'){
					// console.log('unsubscribe to subvalue');
					attrValue.unsubscribe(this.child_subscriptions[callback])
				};
			}
			delete this.child_subscriptions[callback];
		}
	}
}

const resolveValueRef = ({valueRef, observable, resultType='value'})=>{
	// console.log('resolveValueRef observable:',valueRef,observable());
	if (typeof valueRef !== 'string' || !valueRef.startsWith('$root.'))
		return valueRef;

	const path = valueRef.slice(6);
	// console.log('path', path);
	const value = path.split('.').reduce((o, i) => {
		// console.log('reduce:',o, i, typeof i, o['0'], Object.entries(o));
		return o[i];
	}, observable);
	if (resultType === 'value')
		return value();

	return value;
}

const observable = (value)=>{
	return new Observable(value);
}

module.exports = {observable, resolveValueRef};
// export {
// 	observable
// };
