// import * as obs from "./Observables.js";
const obs = require('./Observables.js');

/*EXAMPLE
{
	"type": "object",
	"properties": {
		"name": {
			"type": "string",
			"minLength": 3,
			"description": "Please enter your name"
		},
		"personalData": {
			"type": "object",
			"description": "Please personal data",
			"properties": {
				"age": {
					"type": "number",
					"description": "Please enter your age"
				}
			}
		},
		"isNumber": {
			"type": "validator",
			"description": "Validator checking value is a number",
			"properties":{
				"validation": {
					"type": "boolean",
					"description": "pass fail"
				},
				"errorLevel": {
					"type": "string",
					"description": "the level of error a failure produces",
					"default": "error"
				}
			}
		}
	}
}
*/

class FormDataModel {
	constructor(formDataModel){
		this.form = obs.observable(formDataModel);
	}
}

/*
In the end we want a nested observable in the same structure as the data/model
ie

person (observable object)
-name (observable string)
-age (observable number/string)
location (observable string)

examples of usage

form.subscribe(...);
form.location.subscribe(...)
form.person.subscribe(...)
form.person().age.subscribe(...)
form() -> {"person":{"name":"Jane", "age":30}, "location":"address"}
form.person() -> {"name":"Jane", "age":30}
form.location() -> {"location", "address"}

*/

module.exports = FormDataModel;
