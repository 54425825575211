import template from './ko-col.html';

class ComponentVM {
	constructor(params) {
		this.data = ko_helper.safe_observable(params.data || {});
		this.col_options = ko_helper.safe_observable(params.col_options || {});
		// console.log(`col data: ${JSON.stringify(this.data())}`);
	}

};

window.FormsClient.registerUIComponent({type:'Column',ui_component_name:'ko-col'});

export default {
	name: 'ko-col',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
