class FormsClient
{

	static ui_component_registry = {}

	static registerUIComponent({type, ui_component_name, override}){
		if (FormsClient.ui_component_registry[type] && !override)
			throw new Error(`Type "${type} already exists you can override it with {override:true}"`);

		FormsClient.ui_component_registry[type] = ui_component_name;
	};

	static getUIComponent(type){
		// console.log(`get ${type}`);
		// console.log(this.ui_component_registry);
		return FormsClient.ui_component_registry[type]||'ko-unknown';
	}

	constructor()
	{
	}

	static instanceDataModel(formDataModel) {

	}
}

window.FormsClient= FormsClient;

export default FormsClient;
