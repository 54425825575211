
import template from './form_dialog.html';

/**
 * View Model
 */
class FormDialogVM
{
	/**
	 * ViewModel constructor.
	 * @param {FormDialogClass} dialog - Dialog class
	 */
	constructor(dialog)
	{
		this.dialog = dialog;
		this.form = ko.observable();

	}

	/**
	 * Handle click event for save button
	 */
	save_click()
	{
		this.dialog.close({
			fname: this.fname(),
			lname: this.lname(),
			title: this.title(),
			roles: this.selected_roles()
		});
	}

	/**
	 * Handle click event for close button
	 */
	close_click()
	{
		this.dialog.close(false);
	}
}

/**
 * Dialog
 */
class FormDialogClass
{
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;

		this.viewModel = new FormDialogVM(this); // Name of the ViewModel defined above
		this.viewModel.form({
			form_uuid: bindings.form_uuid,
			form_version_uuid: bindings.form_version_uuid,
			form_data_uuid: bindings.form_data_uuid
		});
	}

	get name() { return 'FormDialog'; }

	async init () {

	};
}

export default {
	name: 'FormDialog',
	dialog_class: FormDialogClass,
	template: template
};
