import template from './forms.html';

class FormsVM
{
	constructor(page)
	{
		this.page = page;
		this.form = ko.observable({});
		this.editMode = ko.observable(false);

		this.forms = ko.observableArray([]);

	}
}

//Handle Interaction with Page
class FormsPage
{
	constructor(bindings)
	{
		this.viewModel = new FormsVM(this);
	}

	async init () {
		document.title = 'Forms';
	}

	async updateData ()
	{
		this.fetchForms();
	}

	fetchForms(){
		Grape.tables.select({schema:'forms', table:'v_forms', filter:[{field:'form_data_uuid', op:'IS_NOT_NULL'}]}).then(
			(res)=>{this.viewModel.forms(res.records)}
		);
	}
}

export default {
	route: '[/]forms',
	page_class: FormsPage,
	template: template
};
