import template from './ko-field.html';

class ComponentVM {
	constructor(params) {
		// console.log(params);
		this.field = ko_helper.safe_observable(params.field || {});
		// console.log(this.field());
		this.name = ko.observable('name');
	}

};

export default {
	name: 'ko-field',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
