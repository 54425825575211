
/* Application entry-point */

import './css/forms_client.css';
import './lib/ko.bindingHandlerElement.js';

import pages from './pages/index.js';
import dialogs from './dialogs/index.js';
// import Plugin from './lib/FormsClientPlugin.js';
import components from './components/index.js';

Grape.utils.register_pages(pages);
Grape.utils.register_dialogs(dialogs);
Grape.utils.register_components(components);

/* "main" */
document.addEventListener('DOMContentLoaded', () => {
	// Grape.plugins.register(Plugin);
});
