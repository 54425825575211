import template from './ko-grid.html';

class ComponentVM {
	constructor(params) {
		this.formData = params.formData;
		this.data = ko_helper.safe_observable(params.data || {});
		this.properties = ko_helper.safe_observable(params.data.properties || {});
		this.gridDisplay = ko.observable(this.data().properties.gridDisplay);
		this.gridColumns = ko.observable(this.data().properties.gridColumns);
		this.gridRows = ko.observable(this.data().properties.gridRows);
		this.gridColumn = ko.observable(this.data().properties.gridColumn);
		this.gridRow = ko.observable(this.data().properties.gridRow);
	}

};

window.FormsClient.registerUIComponent({type:'Grid',ui_component_name:'ko-grid'});

export default {
	name: 'ko-grid',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
