class ComponentVM {
	constructor(params) {
		// console.log('params',params);
		this.formData = params.formData;
		// console.log('formData',this.formData);
		this.data = ko_helper.safe_observable(params.data || {});
		this.visible = ko.observable(this.data().visible ?? true);
		this.id = ko.observable(this.data().properties.scope.replace('#', 'root').replace(/\//g, '-',));
		this.disabled = ko.observable(this.data().properties.disabled ?? false);
		this.label = ko.observable(this.data().properties.label ?? this.id());
		this.index = ko_helper.safe_observable(params.index);
		// console.log('index:', this.label(),this.index());
		this.scope = ko.observable(this.data().properties.scope.replace('$index', this.index()));
		// console.log(this.scope());
		// this.scope = ko.observable(this.data().properties.scope);

	}

};

window.FormsClient.registerUIComponent({type:'Control',ui_component_name:'ko-control'});

export default ComponentVM;
