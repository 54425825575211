import template from './ko-textarea.html';
import ControlVM from './ko-control.js';
import {resolveValueRef} from '../lib/Observables.js';

class ComponentVM extends ControlVM{
	constructor(params) {
		super(params);
		this.showLabel = this.data().properties.showLabel ?? false;
		this.gridColumn = ko.observable(this.data().properties.gridColumn);
		this.gridRow = ko.observable(this.data().properties.gridRow);
		this.width = ko.observable(this.data().properties.width);
		this.rows = ko.observable(this.data().properties.rows);
		this.value = ko.observable();
		this.index = ko_helper.safe_observable(params.index);
		this.scope = ko.observable(this.data().properties.scope.replace('$index', this.index()));
		this.data_model_value = resolveValueRef({
			valueRef: this.scope(),
			observable: this.formData.form,
			resultType: 'observable'
		});
		this.value(this.data_model_value());
		this.value.subscribe((newValue)=>{
			this.data_model_value(newValue)
		});
	}

};

window.FormsClient.registerUIComponent({type:'textarea',ui_component_name:'ko-textarea'});

export default {
	name: 'ko-textarea',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
