import FormsClient from '../lib/FormsClient';
import template from './ko-form.html';
import FormDataModel from '../lib/FormDataModel';

class ComponentVM {
	constructor(params) {
		// console.log(arguments);
		// console.log(params);
		// console.log('form this.formData',this.formData);


		this.form = ko_helper.safe_observable(params.form);
		this.treeData = ko.observable();
		this.form.subscribe((newValue)=>{
			if (!newValue)
				return;

			// console.log('new form ', newValue);
			if (this.form().form_version_def)
				this.renderForm();
		});

		if (!this.form())
			return;

		// console.log('first form ', this.form());
		if (!this.form().form_version_def)
			this.fetchForm();
	}

	renderForm(){
		// this.treeData = ko.observableArray([this.form().form_version_def.form_ui_model]);
		this.formData = new FormDataModel(this.form().form_data || this.form().form_version_def.form_data_model);
		this.treeData([this.form().form_version_def.form_ui_model]);
	}

	fetchForm(){
		Grape.fetches.fetchJSON(`/forms/api/form/load/?form_data_uuid=${this.form().form_data_uuid}&form_version_uuid=${this.form().form_version_uuid}&form_uuid=${this.form().form_uuid}`)
		.then((d)=>{
			this.form(d.form);
		});
		// if a form_uuid get form_def using that and latest version
		// if a form_version_uuid get form_def using that
		// if form_data_uuid is provided get the form_def and then populate it with the data
	}

	submit(){
		// console.log(this.formData.form());
		let payload = {
			form_data_uuid: this.form().form_data_uuid,
			form_data: this.formData.form()
		};
		Grape.fetches.postJSON('/forms/api/form/data/save', payload).then((d)=>{
			if (d.status === 'OK')
				return Grape.alert({type:'success', title:'saved', message:"saved"});
			else
				return Grape.alert_api_error(d);
		})
	}

};

export default {
	name: 'ko-form',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
