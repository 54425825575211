import template from './ko-canvas.html';
import * as obs from '../lib/Observables.js';

class ComponentVM {
	constructor(params) {
		this.data = ko_helper.safe_observable(params.data || {});
		this.formData = params.formData;
		this.canvasElement = ko.observable();
		// console.log(this.canvasElement());
		this.drawImage();
		this.height = ko.observable(10);
		this.width = ko.observable(10);
		this.savedLocations = ko.observableArray();
		this.context = ko.observable();
		this.canvasElement.subscribe((newValue)=>{
			this.context(newValue.getContext('2d'));
		});
		this.scope = ko.observable(this.data().properties.scope);
		this.data_model_value = obs.resolveValueRef({
			valueRef: this.scope(),
			observable: this.formData.form,
			resultType: 'observable'
		});
		this.savedLocations(this.data_model_value() || []);
		this.redrawCircles();
	}

	drawImage(){
		return new Promise((resolve, reject)=>{
			let canvas = this.canvasElement();
			// console.log('canvas', canvas);
			let img = new Image();
			img.src = this.data().properties.image_url;
			img.onload = ()=>{
				this.width(img.width);
				this.height(img.height);
				this.context().drawImage(img, 0, 0);
				resolve();
			};
		})
	}

	rightClick(data, event){
		event.preventDefault();
		const x = event.offsetX;
		const y = event.offsetY;

		if (event.button === 2){ //right click
			for (let i = 0; i < this.savedLocations().length; i++) {
				const location = this.savedLocations()[i];
				const distance = Math.sqrt((location.x - x) ** 2 + (location.y - y) ** 2);
				if (distance <= 10) {
					this.savedLocations.splice(i, 1);
					this.data_model_value.splice(i, 1);
					break;
				}
			}
			this.redrawCircles();
		}
		return false;
	}

	leftClick(data, event){
		event.preventDefault();
		const x = event.offsetX;
		const y = event.offsetY;

		this.savedLocations.push({ x, y });
		this.data_model_value.push({x, y});
		this.drawCircle({x, y});

		return false;
	}

	redrawCircles(){
		this.drawImage().then(()=>{
			for (const location of this.savedLocations()) {
				this.drawCircle(location);
			}
		});
	}

	drawCircle({x, y}){
		this.context().beginPath();
		this.context().arc(x, y, 10, 0, 2 * Math.PI);
		this.context().fillStyle = 'rgba(255, 0, 0, 0.5)';
		this.context().fill();
	}
};

window.FormsClient.registerUIComponent({type:'Canvas',ui_component_name:'ko-canvas'});

export default {
	name: 'ko-canvas',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
